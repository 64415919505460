body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 900;
    src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 900;
    src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Black.ttf) format('opentype');
}