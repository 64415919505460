$lightest-gray: #f7f7f5;
$light-gray: #e2e2e2;
$gray: #999;
$dark-gray: #6f6f6f;
$primary-color:#ff7c4f;
$primary-color-light:rgba(255,124,79,.04);
$blue-light: #f9f9f9;
$dark-text:#333333;

$lightest-blue: #f0f8fc;

$light-blue: #6288a5;
$blue: #326891;
$dark-blue: #4d7b9f;
$darkest-blue: #265e8b;

$dark-gray: #333;
$cloud-orange: #f50;
$white: #fff;
$cloud-gray: #ccc;
$background-gray: #f2f2f2;
