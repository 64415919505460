.track-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  font-weight: 300;
  padding: 8px;
  transition: opacity 0.2s ease-out;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  background-color: #fbfbfb;
  box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 50%);
  .track-uploader-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .track-uploader-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      box-shadow: 1px 1px 5px lightgrey;
      // background: image_url('missing.png');
      // background-size: contain;

      img {
        flex-shrink: 0;
        min-width: 100%;
        max-width: 100%;
      }
    }
    .track-uploader-name {
      color: black;
      margin-left: 6px;
    }
  }

  .track-item {
    display: flex;
    .track-image-box {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      width: 160px;
      height: 160px;
      min-width: 160px;
      min-height: 160px;
      // background: image_url('missing.png');
      // background-size: contain;
      img {
        flex-shrink: 0;
        min-width: 100%;
        max-width: 100%;
      }
    }
    .track-details {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 15px;
      .td-top {
        box-sizing: border-box;
        display: flex;
        height: 50px;
        align-items: center;
        .ti-play {
          box-sizing: border-box;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPlBsYXkgMjg8L3RpdGxlPjxwYXRoIGQ9Ik0wIDE0bDEuODQ2LTdMMCAwbDggNy04IDd6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
          background-size: 12px 18px;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          background-color: $cloud-orange;
          box-shadow: 1px 1px 5px lightgrey;
          cursor: pointer;
        }
        .playing {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPlBhdXNlIDI4PC90aXRsZT48cGF0aCBkPSJNNSAwdjEyaDNWMEg1ek0wIDB2MTJoM1YwSDB6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
          background-color: rgb(255, 51, 0);
        }
        .ti-upload-det {
          height: 80%;
          margin-left: 6px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          aside {
            padding: 2.5px 0px;
          }
        }
        .ti-description {
          color: gray;
          font-size: 12px;
        }
        .ti-description:hover {
          color: black;
        }
        .ti-title {
          color: black;
          font-size: 16px;
          text-decoration: none;
          opacity: 0.8;
          text-overflow: ellipsis;
          font-family: 'Source Sans Pro';
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
        .ti-title:hover {
          opacity: 1;
        }
        @media screen and (max-width: 500px) {
          .ti-title {
            width: 80%;
          }
          .ti-play {
            background-size: 10px 16px;
            max-width: 40px;
            max-height: 36px;
          }
        }
      }
      .sound-bar {
        width: 100%;
        height: 60px;
        position: relative;
        span {
          position: absolute;
          width: 100%;
          height: 30px;
          z-index: 4;
          background-color: rgba(255, 255, 255, 0.75);
          bottom: 0;
          pointer-events: none;
        }
      }
      .comment-bar {
        width: 100%;
        height: 32px;
        background-color: black;
      }
    }
  }
}

.button-bar {
  display: flex;
  margin-top: 10px;
  height: 22px;
  text-align: center;
  .controller-btn {
    border: 1px lightgray solid;
    margin: 0 5px;
    width: 70px;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 4px;
  }
  .controller-btn:hover {
    border-color: gray;
  }
}

.like-btn {
  color: black;
  border: 2px solid black;
}

.liked {
  color: $cloud-orange;
  border: 2px solid $cloud-orange;
}

.track-player-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.playButton {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 43px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  background: transparent;
}

.left-player-time {
  position: absolute;
  bottom: 20px;
  z-index: 4;
}

.right-player-time {
  position: absolute;
  bottom: 20px;
  z-index: 4;
  right: 0px;
}

.btn-zoomsounds {
  padding: 7px 10px;
  border-radius: 3px;
  line-height: 1;
  white-space: nowrap;
  margin-right: 5px;
  font-weight: 700;
  color: #222;
  text-decoration: none;
  background-color: #eaeaea;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  transition-property: color, background-color, border;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.time-player-length {
  background-color: black;
  font-size: 10px;
  line-height: normal;
}

.color-gray {
  color: rgba(255, 255, 255, 0.4);
}

.playButton:hover {
  background-color: transparent;
}

.like-button {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: button;
  position: relative;
  margin: 0px 2px;
  border-radius: 3px;
  background-color: #eaeaea;
  border: none;
  cursor: pointer;
  color: #333;
  font-weight: 100;
  vertical-align: baseline;
  -webkit-user-select: none;
  box-sizing: border-box;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  font-family: Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana,
    Tahoma, sans-serif;
  text-align: center;
  min-width: 30px;
}
.like-button:hover {
  background-color: #fff;
}
.top-right-item {
  position: relative;
  width: 0;
}

.like-button-active {
  color: $cloud-orange;
}

.top-right-buttons {
  margin-top: 10px;
  flex-direction: row;
  display: flex;
}
