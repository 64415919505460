.drawer-item {
  width: 100%;
  height: 80px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $cloud-gray;
  background-color: $blue-light;
  padding-top: 10px;
  cursor: pointer;
}

.drawer-item-text {
  text-align: center;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
  font-style: normal;
}

.subscription-banner {
  background-color: #d7d6d6;
  padding: 5px;
  // position: fixed;
  // top:0;
  width: 100%;
}

.drawer-item:hover {
  background-color: #f6f6f6;
}

.subscription-text {
  margin: 0;
  text-align: center;
  font-size: 11px;
  font-family: 'Source Sans Pro';
  font-weight: 700;
}

.drawer-item:hover > h4 {
  color: $primary-color;
}
