.heading-h3 {
  color: $primary-color;
  font-size: 32px;
  font-weight: 700;
  font-family: "Source Sans Pro";
}
.heading-h5 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Source Sans Pro";
}

.address-text{
  font-family: "Source Sans Pro";
  font-weight: 400;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 18px;
}

.heading-h2 {
  font-size: 40px;
  font-family: "Source Sans Pro";
  font-weight: 700;
  color: $dark-text;
}

.text-center {
  text-align: center;
}

.card-light-primary {
  background-color: $primary-color-light;
  padding: 24px;
  padding-top: 30px;
}

.link-primary {
  font-style: normal;
  text-decoration: none;
  color: #46494a !important;
}

.link-primary:hover {
  color: $primary-color !important;
}

.mindfullnes-img {
  max-width: 100%;
  height: auto;
}

.sound-medias{
  display: flex;
  flex-direction: row;
  
}

.sound-medias-item{
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  padding-top: 10px;
}

.margin-0{
  margin: 0;
  
}

.sep-shadow {
  margin-top: 15px;
  margin-bottom: 70px;
  box-sizing: border-box;
  flex-grow: 2;
  height: 1px;
  overflow: visible;
  border: none;
  background: radial-gradient(
      ellipse at 50% -50%,
      #e0dede 0px,
      rgba(255, 255, 255, 0) 80%
    )
    repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.sep-shadow::after {
  display: block;
  height: 6px;
  width: 100%;
  content: '';
  background: radial-gradient(
    ellipse at 50% -50%,
    rgba(0, 0, 0, 0.5) 0,
    rgba(255, 255, 255, 0) 65%
  );
}

.vertical-space-20 {
  margin: 60px;
}

.font-size-18 {
  font-size: 18px;
}

.fusion-social-network-icon {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  text-align: center;
  box-sizing: border-box;
  box-shadow: none !important;
  background-clip: inherit;
  border: 1px solid #46494a;
  text-decoration: none;
  margin: 0 3.5px 10px;
  border-radius: 50px;
  padding: 8px;
  font-size: 18px;
  color: #ff7c4f;
  background-color: $primary-color-light;
  border-color: #fffbf7;
}

.color-primary {
  color: $primary-color;
}
